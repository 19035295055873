import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import { utility } from '../../utils/utility';
import { deepOrange, deepPurple, green, lightGreen, blue, pink } from '@material-ui/core/colors';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const [_avatarclass, setAvatarClass] = useState(Math.floor(Math.random() * 5))
  //const loggedInUser = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null;
  const loggedInUser = sessionStorage.getItem('loggedInUser') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : null;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const profileavatar = loggedInUser ? loggedInUser.account.type === 2 ? user.avatar : '/static/mock-images/avatars/avatar-siegbert_gottfried.png' : undefined;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    console.log('handleLogout')
    try {
      await logout();
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('loggedInUser');
      sessionStorage.removeItem('return_url');
      // window.location.href = '/';
      handleClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  const useStyles = makeStyles((theme) => ({

    linkText: {
      textDecoration: 'none',
      textTransform: 'capitalize',
      color: '#172b4d',
      fontFamily: 'system-ui',
      fontWeight: 500,
      fontSize: '0.875rem',
      marginTop: '5px'
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      fontSize: '15px'
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
      fontSize: '15px'
    },
    greencolor: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      fontSize: '15px'
    },
    bluecolor: {
      color: theme.palette.getContrastText(blue[500]),
      backgroundColor: blue[500],
      fontSize: '15px'
    },
    pinkcolor: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
      fontSize: '15px'
    },
  }));

  const classes = useStyles();

  let avatarname;
  //const _avatarclass = Math.floor(Math.random() * 5);
  switch (_avatarclass) {
    case 0: avatarname = classes.orange; break;
    case 1: avatarname = classes.pinkcolor; break;
    case 2: avatarname = classes.bluecolor; break;
    case 3: avatarname = classes.greencolor; break;
    case 4: avatarname = classes.purple; break;
    default: avatarname = classes.orange;
  }
  const name = (loggedInUser && loggedInUser.account && loggedInUser.account.firstName) + ' ' + (loggedInUser && loggedInUser.account && loggedInUser.account.lastName);
  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar
          className={avatarname}
          sx={{
            height: 32,
            width: 32
          }}
        >
          {utility.getInitials(name)}
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {loggedInUser && loggedInUser.account && loggedInUser.account.firstName} {loggedInUser && loggedInUser.account && loggedInUser.account.lastName}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            VMA+
          </Typography>
          <Box mt={1}>
            {loggedInUser && loggedInUser.account && (loggedInUser.account.ccProvided && loggedInUser.account.membershipAgreement && !loggedInUser.account.changePasswordRequired) &&
              <RouterLink
                to='/dashboard/updatepassword'
                className={classes.linkText}
              >
                Change Password
              </RouterLink>
            }
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          {/* <MenuItem
            component={RouterLink}
            to="/dashboard/social/profile"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Profile
                </Typography>
              )}
            />
          </MenuItem> */}
          {/* <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              )}
            />
          </MenuItem> */}
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
