import type { FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Hidden, IconButton, Box, Toolbar, makeStyles, List, useMediaQuery, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
// import ContactsPopover from './ContactsPopover';
// import ContentSearch from './ContentSearch';
// import LanguagePopover from './LanguagePopover';
import Logo from '../Logo';
// import NotificationsPopover from './NotificationsPopover';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: "white",
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);



const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  //const loggedInUser = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null;
  const loggedInUser = sessionStorage.getItem('loggedInUser') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : null;
  const navLinks = [];

  if (loggedInUser && loggedInUser.account) {
    // if(!mobileDevice && loggedInUser.account.type!=0){
    //   console.log("type of account",loggedInUser.account.type)
    //   navLinks.push({
    //     title: 'Dashboard',
    //     path: '/dashboard/home',

    //   });
    // }
    if (loggedInUser.account.type === 0 && !mobileDevice) {
      navLinks.push({
        title: 'Home',
        path: '/dashboard/admin/home',

      });
      navLinks.push({
        title: 'Doctors',
        path: '/dashboard/admin/doctors'
      });
      navLinks.push({
        title: 'Members',
        path: '/dashboard/admin/patients'
      });
      navLinks.push({
        title: 'Non Stripe Members',
        path: '/dashboard/admin/nonstripemembers'
      });
      navLinks.push({
        title: 'Subscription',
        path: '/dashboard/admin/subscriptions'
      });
      navLinks.push({
        title: 'Stripe Members',
        path: '/dashboard/admin/stripemembers'
      });
      navLinks.push({
        title: 'Settings',
        path: '/dashboard/admin/stripesettings'
      });
    }
    else if (loggedInUser.account.type === 1 && !mobileDevice) {
      navLinks.push({
        title: 'Dashboard',
        path: '/dashboard/home',

      });
      navLinks.push({
        title: 'Messages',
        path: '/dashboard/messages',

      });
      navLinks.push({
        title: 'Members',
        path: '/dashboard/members'
      });
      /*  navLinks.push({
         title: 'Change Password',
         path: '/dashboard/updatepassword'
       }); */
    }

    else if (!mobileDevice && loggedInUser.account.type === 2) {
      navLinks.push({
        title: 'Dashboard',
        path: '/dashboard/home',

      });
      navLinks.push({
        title: 'Your Profile',
        path: '/dashboard/healthprofile'
      });

      navLinks.push({
        title: 'Health Profile',
        path: '/dashboard/healthcondition'
      });
      navLinks.push({
        title: 'Account Information',
        path: '/dashboard/subscriptionmanagement'
      });
      /* navLinks.push({
        title: 'Change Password',
        path: '/dashboard/updatepassword'
      }); */
    }
  } else {
    const navigate = useNavigate();
    navigate('/login');
  }

  let location = useLocation();
  const theme = useTheme();

  const useStyles = makeStyles({
    navDisplayFlex: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 0.8
    },
    linkText: {
      textDecoration: 'none',
      color: '#737373',
      marginLeft: '20px',
      fontWeight: 500,
      fontSize: '14px',
      opacity: 1
    },
    selectedLinkText: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginLeft: '20px',
      fontSize: '14px',
      opacity: 1,
      fontWeight: 700
    }
  });

  const classes = useStyles();

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden smUp>
          <IconButton
            color="default"
            //color="black"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Hidden>
        {/* <Box
          sx={{
            flexGrow: 1
          }}
        /> */}
        {mobileDevice ?
          <RouterLink to="/"
            style={{ marginLeft: 24, marginRight: 24 }}>
            <Logo
              sx={{
                height: 50,
                width: 100,
              }}

            />
          </RouterLink> :

          <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex} // this
          >
            {navLinks.map(({ title, path }) => {
              if (location.pathname.includes('/patient/signupprogress')) {
                return <div />
              }

              return (
                <RouterLink
                  className={location.pathname.includes(path) ? classes.selectedLinkText : classes.linkText}
                  to={path}
                >
                  {title}
                </RouterLink>
              )

            })}
          </List>}
        {/* <LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{
          // ml: 2
          m: 1
        }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
