import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  Hidden,
  IconButton,
  Link,
  Theme,
  Toolbar,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onSidebarMobileOpen } = props;
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden smUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 70,
                width: 70
              }}
            />
          </RouterLink>
        </Hidden>
        {mobileDevice ?
          <RouterLink to="/"
            style={{ marginLeft: '16%', marginRight: 24 }}>
            <Logo
              sx={{
                height: 50,
                width: 100,
              }}

            />
          </RouterLink> : null}
        <Box sx={{ flexGrow: 1 }} />
        <Hidden smDown>
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/faq"
            underline="none"
            variant="body1"
            mr={5}
          >
            FAQs
          </Link>
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/pricing"
            underline="none"
            variant="body1"
            mr={5}
          >
            Pricing
          </Link>
          {/* <Chip
            color="primary"
            label="NEW"
            size="small"
            sx={{
              maxHeight: 20,
              ml: 1,
              mr: 2
            }}
          /> */}
          {/* <Link
            color="textSecondary"
            component={RouterLink}
            to="/contact"
            underline="none"
            variant="body1"
            mr={5}
          >
            Contact
          </Link> */}
          {/* <Link
            color="textSecondary"
            component={RouterLink}
            to="/doctor/signup"
            underline="none"
            variant="body1"
            mr={5}
          >
            Doctor Signup
          </Link> */}
          {/* <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          <Button
            color="primary"
            component="a"
            href="https://material-ui.com/store/items/devias-kit-pro"
            size="small"
            target="_blank"
            variant="contained"
          >
            Get the kit
          </Button> */}
        </Hidden>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
