import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    const loggedInUser = sessionStorage.getItem('loggedInUser') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : null;
    /*if (loggedInUser && loggedInUser.account && loggedInUser.account.membershipAgreement == false) {
      return <Navigate to='/membership' />;
    }*/
    //alert(loggedInUser && loggedInUser.account && loggedInUser.account.type == 0);
    if (loggedInUser && loggedInUser.account && loggedInUser.account.changePasswordRequired == true) {
      return <Navigate to='/dashboard/patient/signupprogress/changepassword' />;
    } else if (loggedInUser && loggedInUser.account && loggedInUser.account.membershipAgreement == false) {
      return <Navigate to='/dashboard/patient/signupprogress/membership' />;
    } else if (loggedInUser && loggedInUser.account && loggedInUser.account.ccProvided == false) {
      return <Navigate to='/dashboard/patient/signupprogress/creditcard' />;
    } else if (loggedInUser && loggedInUser.account && loggedInUser.account.healthProfileRequired && loggedInUser.account.healthProfileRequired == true) {
      return <Navigate to='/dashboard/patient/signupprogress/yourprofile' />;
    } else if(loggedInUser && loggedInUser.account && loggedInUser.account.type == 0){
      return <Navigate to= '/dashboard/admin/home' />
    }else{
    return <Navigate to="/dashboard/home" />;
    }
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
