import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
    conversations: []
};

const slice = createSlice({
    name: 'conversations',
    initialState,
    reducers: {
        getConversations(
            state: any,
            action: PayloadAction<any>
        ): void {
            const { conversations } = action.payload;
            state.conversations = conversations;
        },
    }
});

export const { reducer } = slice;

export const getConversations = () => async (dispatch): Promise<void> => {
    const response = await axios.get('/conversations');
    dispatch(slice.actions.getConversations(response.data));
};


export default slice;