import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
//import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Members',
        path: '/dashboard/members',
        icon: <UsersIcon fontSize="small" />
      },
      {
        title: 'Mail',
        path: '/dashboard/mail',
        icon: <MailIcon fontSize="small" />
      },
      // {
      //   title: 'Finance',
      //   path: '/dashboard/finance',
      //   icon: <ShoppingBagIcon fontSize="small" />
      // },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      }
    ]
  }
  // {
  //   title: 'Management',
  //   items: [
  //     {
  //       title: 'Customers',
  //       path: '/dashboard/customers',
  //       icon: <UsersIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/customers'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/customers/1'
  //         },
  //         {
  //           title: 'Edit',
  //           path: '/dashboard/customers/1/edit'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Products',
  //       path: '/dashboard/products',
  //       icon: <ShoppingCartIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/products'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/dashboard/products/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Orders',
  //       icon: <FolderOpenIcon fontSize="small" />,
  //       path: '/dashboard/orders',
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/orders'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Invoices',
  //       path: '/dashboard/invoices',
  //       icon: <ReceiptIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/invoices'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/invoices/1'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Platforms',
  //   items: [
  //     {
  //       title: 'Projects',
  //       path: '/dashboard/projects',
  //       icon: <BriefcaseIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Browse',
  //           path: '/dashboard/projects/browse'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/projects/1'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/dashboard/projects/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social',
  //       path: '/dashboard/social',
  //       icon: <ShareIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Profile',
  //           path: '/dashboard/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           path: '/dashboard/social/feed'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Apps',
  //   items: [
  //     {
  //       title: 'Kanban',
  //       path: '/dashboard/kanban',
  //       icon: <ClipboardListIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Mail',
  //       path: '/dashboard/mail',
  //       icon: <MailIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Chat',
  //       path: '/dashboard/chat',
  //       icon: <ChatAltIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Calendar',
  //       path: '/dashboard/calendar',
  //       icon: <CalendarIcon fontSize="small" />
  //     }
  //   ]
  // }
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const loggedInUser = sessionStorage.getItem('loggedInUser') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : null;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/dashboard">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        {/* <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box> */}
        <Divider />
        {/* <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  // return (
  //   <>
  //     <Hidden lgUp>
  //       <Drawer
  //         anchor="left"
  //         onClose={onMobileClose}
  //         open={openMobile}
  //         PaperProps={{
  //           sx: {
  //             backgroundColor: 'background.paper',
  //             width: 280
  //           }
  //         }}
  //         variant="temporary"
  //       >
  //         {content}
  //       </Drawer>
  //     </Hidden>
  //     <Hidden lgDown>
  //       <Drawer
  //         anchor="left"
  //         open
  //         PaperProps={{
  //           sx: {
  //             backgroundColor: 'background.paper',
  //             height: 'calc(100% - 64px) !important',
  //             top: '64px !Important',
  //             width: 280
  //           }
  //         }}
  //         variant="persistent"
  //       >
  //         {content}
  //       </Drawer>
  //     </Hidden>
  //   </>
  // );
  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: 256
          }
        }}
      >
        {loggedInUser && loggedInUser.account.type == 0 &&
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/admin/home">
              <Logo />
            </RouterLink>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/dashboard/admin/home"
                underline="none"
                variant="body1"
              >
                Home
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/dashboard/admin/doctors"
                underline="none"
                variant="body1"
              >
                Doctors
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/dashboard/admin/patients"
                underline="none"
                variant="body1"
              >
                Patients
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/dashboard/admin/subscriptions"
                underline="none"
                variant="body1"
              >
                Subscriptions
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/dashboard/admin/stripemembers"
                underline="none"
                variant="body1"
              >
                Stripe Members
              </Link>
            </Box>
          </Box>}
        {loggedInUser && loggedInUser.account.type != 0 &&
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/home">
              <Logo />
            </RouterLink>
            {/* <Box
            sx={{
              display: 'flex',
              pb: 2,
              pt: 3
            }}
          >
            <Link
              color="textSecondary"
              component={RouterLink}
              to="/browse"
              underline="none"
              variant="body1"
            >
              Browse Components
            </Link>
            <Chip
              color="primary"
              label="NEW"
              size="small"
              sx={{
                maxHeight: 20,
                ml: 1,
                mr: 2
              }}
            />
          </Box>
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/docs"
            underline="none"
            variant="body1"
          >
            Documentation
          </Link>
          <Button
            color="primary"
            component="a"
            href="https://material-ui.com/store/items/devias-kit-pro"
            size="small"
            sx={{ mt: 4 }}
            target="_blank"
            variant="contained"
          >
            Get the kit
          </Button>*/}

            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/dashboard/home"
                underline="none"
                variant="body1"
              >
                Dashboard
              </Link>
            </Box>

            <Box
              sx={{
                display: 'flex',
                pb: 2
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to={loggedInUser && loggedInUser.account && loggedInUser.account.type &&
                  loggedInUser.account.type === 2 ? "/dashboard/healthprofile" : "/dashboard/messages"}
                underline="none"
                variant="body1"
              >
                {loggedInUser && loggedInUser.account && loggedInUser.account.type &&
                  loggedInUser.account.type === 2 ? "Your Profile" : "Messages"}
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to={loggedInUser && loggedInUser.account && loggedInUser.account.type &&
                  loggedInUser.account.type === 2 ? "/dashboard/healthcondition" : "/dashboard/members"}
                underline="none"
                variant="body1"
              >
                {loggedInUser && loggedInUser.account && loggedInUser.account.type &&
                  loggedInUser.account.type === 2 ? "Health Profile" : "Members"}
              </Link>
            </Box>
            {loggedInUser && loggedInUser.account && loggedInUser.account.type && loggedInUser.account.type == 2 &&
              <Box
                sx={{
                  display: 'flex',
                  pt: 2
                }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to={"/dashboard/subscriptionmanagement"}
                  underline="none"
                  variant="body1"
                >
                  Account Information
                </Link>
              </Box>}
          </Box>}
      </Drawer>
    </Hidden>
  );
};


DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
