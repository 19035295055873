import { differenceInCalendarYears } from 'date-fns';
// import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-timezone';
import { deepOrange, deepPurple, green, lightGreen, blue, pink } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';



export class utility {



    public static checkUrl(value: string) {
        const _regexp = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
        const __regexp = new RegExp(/^(?![^\n]*\.$)(?:https?:\/\/)?(?:(?:[2][1-4]\d|25[1-5]|1\d{2}|[1-9]\d|[1-9])(?:\.(?:[2][1-4]\d|25[1-5]|1\d{2}|[1-9]\d|[0-9])){3}(?::\d{4})?|[a-z\-]+(?:\.[a-z\-]+){2,})$/);
        if (_regexp.test(value)) {
            return value;
        } else if (value && value.indexOf('https') === -1 && value.indexOf('http') !== -1) {
            return value;
        } else if (value && value.indexOf('https') !== -1) {
            return value;
        } else if (value && value.indexOf('https') === -1 && value.indexOf('http') === -1) {
            return 'http://' + value;
        }
        return value;
    }

    public static countYears(dateOfBirth: Date | string) {
        console.log(differenceInCalendarYears(new Date(), new Date(dateOfBirth)));
        return differenceInCalendarYears(new Date(), new Date(dateOfBirth));
    }

    public static isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    public static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public static AvatarColors(index?: number): string {
        if (index == undefined || index == 0) {
            index = 6;
        }

        let avatarname;
        const _avatarclass = Math.floor(Math.random() * 5);
        switch (_avatarclass) {
            case 0: avatarname = 'orange'; break;
            case 1: avatarname = 'pink'; break;
            case 2: avatarname = 'blue'; break;
            case 3: avatarname = 'green'; break;
            case 4: avatarname = 'purple'; break;
            case 5: avatarname = 'orange'; break;
            default: avatarname = 'orange';
        }

        return avatarname;
    }

    public static getInitials(name: string): string {
        console.log(name);
        const fullName = name.split(' ');
        const initials = fullName.shift().charAt(0) + fullName.shift().charAt(0);
        return initials.toString().toUpperCase();
    }

    // public static UTCToZoneTime(date: Date | string, timeZone: string) {
    //     const zonedDate = utcToZonedTime(date, timeZone);
    //     return zonedDate;
    // }





}