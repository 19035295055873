import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    sessionStorage.setItem('return_url', location.pathname);
    return <Navigate to={"/"} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }



  if (sessionStorage.getItem('return_url')) {
    const returnUrl = sessionStorage.getItem('return_url');
    sessionStorage.removeItem('return_url');

    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
      const loggedInUser = sessionStorage.getItem('loggedInUser') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : null;
      /*if (loggedInUser && loggedInUser.account && loggedInUser.account.membershipAgreement == false) {
        return <Navigate to='/membership' />;
      }*/
      if (loggedInUser && loggedInUser.account && loggedInUser.account.changePasswordRequired == true) {
        return <Navigate to='/dashboard/patient/signupprogress/changepassword' />;
      } else if (loggedInUser && loggedInUser.account && loggedInUser.account.membershipAgreement == false) {
        return <Navigate to='/dashboard/patient/signupprogress/membership' />;
      } else if (loggedInUser && loggedInUser.account && loggedInUser.account.ccProvided == false) {
        return <Navigate to='/dashboard/patient/signupprogress/creditcard' />;
      } else if (loggedInUser && loggedInUser.account && loggedInUser.account.healthProfileRequired && loggedInUser.account.healthProfileRequired == true) {
        return <Navigate to='/dashboard/patient/signupprogress/yourprofile' />;
      } else if(loggedInUser && loggedInUser.account && loggedInUser.account.type == 0){
        return <Navigate to= '/dashboard/admin/home' />
      }
    }


    window.location.pathname = returnUrl;
    // return <Navigate to={returnUrl} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
