import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// import AxiosMockAdapter from 'axios-mock-adapter';


//http://medicalalliance-alb-2128694225.us-west-2.elb.amazonaws.com/api/v1/'
// http://dev-api-alb-809920793.us-east-1.elb.amazonaws.com/api/v1/

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
});

axiosInstance.interceptors.request.use(function (config) {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.status === 500 && response.data.error && (response.data.error.code == 102002 || response.data.error.code == 401)) {
      localStorage.clear();
      sessionStorage.removeItem('loggedInUser');
      sessionStorage.removeItem('accessToken');
      window.location.href = '/';
      return;
    }
    return new Promise((resolve, reject) => {
      if (response.data.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    })
  },
  (error) => Promise.reject((
    error.response && error.response.data
  ) || 'Something went wrong')
);

// export const mock = new AxiosMockAdapter(axiosMockInstance, { delayResponse: 0 });
export default axiosInstance;