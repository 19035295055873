import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
// import AuthGuard from './components/AuthGuard';
import { Navigate } from 'react-router-dom';
// import AuthGuard from './components/AuthGuard';
//  import BlogLayout from './components/blog/BlogLayout';
//  import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
// import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import MainLayout from './components/MainLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';



const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const DashboardHome = Loadable(lazy(() => import('./pages/browse/DashboardHome')));
const ViewYourProfile = Loadable(lazy(() => import('./pages/Patient/PatientSignup/ViewYourProfile')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const PaymentDetails = Loadable(lazy(() => import('./pages/dashboard/PaymentDetails')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));
const VerifyAndCreateAccount = Loadable(lazy(() => import('./pages/Patient/PatientSignup/VerifyAndCreateAccount')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));
const PatientSignup = Loadable(lazy(() => import('./pages/Patient/PatientSignup/')));
const Membership = Loadable(lazy(() => import('./pages/Patient/PatientSignup/Membership')));
const DoctorSignup = Loadable(lazy(() => import('./pages/Doctor/Signup')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const Messages = Loadable(lazy(() => import('./pages/browse/Messages')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const PasswordNew = Loadable(lazy(() => import('./pages/authentication/PasswordNew')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const AdminHome = Loadable(lazy(() => import('./pages/Admin/AdminHome')));
const AdminDoctors = Loadable(lazy(() => import('./pages/Admin/Doctors')));
const AdminPatients = Loadable(lazy(() => import('./pages/Admin/Patients')));
const AdminSubscriptions = Loadable(lazy(() => import('./pages/Admin/Subscriptions')));
const AdminStripeMembers = Loadable(lazy(() => import('./pages/Admin/StripeMembers')));
const StripeSettings = Loadable(lazy(() => import('./pages/Admin/StripeSettings')));
const AdminNonStripeMembers = Loadable(lazy(() => import('./pages/Admin/NonStripeMembers')));
const AdminSelectedPatients = Loadable(lazy(() => import('./pages/Admin/SelectedPatients')));
const AdminEditPatients = Loadable(lazy(() => import('./pages/Admin/EditPatients')));
const EditHealthProfile = Loadable(lazy(() => import('./pages/dashboard/EditHealthProfile')));
const EditYourProfile = Loadable(lazy(() => import('./pages/dashboard/EditYourProfile')));
const SubscriptionManagement = Loadable(lazy(() => import('./pages/dashboard/SubscriptionManagement')));
const ConfirmAccount = Loadable(lazy(() => import('./pages/ConfirmAccount')));
const UpdatePassword = Loadable(lazy(() => import('./pages/dashboard/UpdatePassword')));
const FAQ = Loadable(lazy(() => import('./pages/Faq')));
const Emailer = Loadable(lazy(() => import('./pages/Emailer')));
const SignupProgress = Loadable(lazy(() => import('./pages/Patient/SignupProgress/')));
const ViewHealthProfile = Loadable(lazy(() => import('./pages/Patient/PatientSignup/ViewHealthProfile')));


const routes: PartialRouteObject[] = [
  {
    path: '/login',
    element: (
      <GuestGuard>
        <Home />
      </GuestGuard>
    )
  },
  {
    path: '/register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: '/membership',
    element: <Membership afterLogin />
  },
  {
    path: '/verify-code',
    element: <VerifyCode />
  },
  {
    path: '/verify',
    element: <VerifyAndCreateAccount DoctorSignup />
  },
  {
    path: 'password-reset',
    element: <PasswordReset />
  },
  {
    path: 'password-new',
    element: <PasswordNew />
  },
  {
    path: 'password-recovery',
    element: <PasswordRecovery />
  },
  {
    path: 'confirm-account',
    element: <ConfirmAccount />
  },
  {

    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <DashboardHome />
      },
      {
        path: '/home',
        element: <DashboardHome />
      },
      {
        path: '/members',
        element: <CustomerList />
      },
      {
        path: '/members/conversations/:memberId',
        element: <Messages />
      },
      {
        path: '/members/conversations/:conversationId/messages/:memberId',
        element: <SocialFeed memberConversations />
      },
      // {
      //   path: '/paymentdetails/:memberId',
      //   element: <PaymentDetails />
      // },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'messages',
        element: <Messages />
      },
      {
        path: 'messages',
        children: [
          {
            path: '/',
            element: <Messages />
          },
          {
            path: ':conversationId',
            element: <SocialFeed />
          }
        ]
      },

      {
        path: '/healthcondition',
        element: <EditHealthProfile />
      },
      {
        path: '/healthprofile',
        element: <EditYourProfile />
      },
      {
        path: '/subscriptionmanagement',
        element: <SubscriptionManagement />
      },
      {
        path: '/viewyourprofile/:memberId',
        element: <ViewYourProfile />
      },
      {
        path: '/viewhealthprofile/:memberId',
        element: <ViewHealthProfile />
      },
      {
        path: '/updatepassword',
        element: <UpdatePassword />
      },
      {
        path: '/patient/signupprogress/:step',
        element: <SignupProgress />
      },
      {
        path: '/admin/home',
        element: <AdminHome />
      },
      {
        path: '/admin/doctors',
        element: <AdminDoctors />
      },
      {
        path: '/admin/patients',
        element: <AdminPatients />
      },
      {
        path: '/admin/subscriptions',
        element: <AdminSubscriptions />
      },
      {
        path: '/admin/stripemembers',
        element: <AdminStripeMembers />
      },
      {
        path: '/admin/nonstripemembers',
        element: <AdminNonStripeMembers />
      },
      {
        path: '/admin/stripesettings',
        element: <StripeSettings />
      },
      {
        path: '/admin/selectedpatients/:doctorId',
        element: <AdminSelectedPatients />
      },
      {
        path: '/admin/editpatients/:memberId',
        element: <AdminEditPatients />
      },
      {
        path: '/admin/doctors/paymentdetails/:memberId',
        element: <PaymentDetails />
      },
      {
        path: '/admin/patients/paymentdetails/:memberId',
        element: <PaymentDetails />
      },
      {
        path: '/admin/nonstripemembers/paymentdetails/:memberId',
        element: <PaymentDetails />
      },
      {
        path: '/members/paymentdetails/:memberId',
        element: <PaymentDetails />
      },

    ]
  },
  // {
  //   path:'/admin',
  //   element:(
  //     <GuestGuard>
  //       <DashboardLayout />
  //     </GuestGuard>
  //   ),
  //   children:[
  //     {
  //       path:'/Home',
  //       element:<AdminHome />
  //     }
  //   ]
  // },
  {
    path: '*',
    element: (
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '/',
        element: <Home />
        //element: <Login />
      },
      {
        path: '/pricing',
        element: <Pricing />
      },
      {
        path: '/faq',
        element: <FAQ />
      },
      {
        path: '/patient/signup/:step',
        element: <PatientSignup />
      },
      {
        path: '/doctor/signup',
        element: <DoctorSignup />
      },
      {
        path: '/doctor/thanks',
        element: <Emailer />
      },
      {
        path: '/emailer',
        element: <Emailer />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

//  #region PAGES LIST
// Browse pages

// const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
// const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
// const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
// const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
// const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
// const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
// const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
// const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
// const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
// const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
// const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
// const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
// const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

// const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
// const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
// const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
// // Blog pages

// const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
// const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
// const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

// const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
// const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
// const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
// const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
// const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
// const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
// const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// Docs pages

// const Docs = Loadable(lazy(() => import('./pages/Docs')));

// // Error pages

// const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
// const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
// const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// // Projects pages

// const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
// const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// // Social pages

// const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// // Other pages

// const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
// const Contact = Loadable(lazy(() => import('./pages/Contact')));

// #endregion

// const routes: PartialRouteObject[] = [
//   {
//     path: '*',
//     children: [
//       {
//         path: 'login',
//         element: (
//           <GuestGuard>
//             <Login />
//           </GuestGuard>
//         )
//       },
//       {
//         path: 'login-unguarded',
//         element: <Login />
//       },
//       {
//         path: 'password-recovery',
//         element: <PasswordRecovery />
//       },
//       {
//         path: 'password-reset',
//         element: <PasswordReset />
//       },
//       {
//         path: 'register',
//         element: (
//           <GuestGuard>
//             <Register />
//           </GuestGuard>
//         )
//       },
//       {
//         path: 'register-unguarded',
//         element: <Register />
//       },
//       {
//         path: 'verify-code',
//         element: <VerifyCode />
//       }
//     ]
//   },
//   {
//     path: 'contact',
//     element: <Contact />
//   },
//   {
//     path: 'dashboard',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     children: [
//       {
//         path: '/',
//         element: <Overview />
//       },
//       {
//         path: 'account',
//         element: <Account />
//       },
//       {
//         path: 'analytics',
//         element: <Analytics />
//       },
//       {
//         path: 'calendar',
//         element: <Calendar />
//       },
//       {
//         path: 'chat',
//         children: [
//           {
//             path: '/',
//             element: <Chat />
//           },
//           {
//             path: 'new',
//             element: <Chat />
//           },
//           {
//             path: ':threadKey',
//             element: <Chat />
//           }
//         ]
//       },
//       {
//         path: 'customers',
//         children: [
//           {
//             path: '/',
//             element: <CustomerList />
//           },
//           {
//             path: ':customerId',
//             element: <CustomerDetails />
//           },
//           {
//             path: ':customerId/edit',
//             element: <CustomerEdit />
//           }
//         ]
//       },
//       {
//         path: 'invoices',
//         children: [
//           {
//             path: '/',
//             element: <InvoiceList />
//           },
//           {
//             path: ':invoiceId',
//             element: <InvoiceDetails />
//           }
//         ]
//       },
//       {
//         path: 'kanban',
//         element: <Kanban />
//       },
//       {
//         path: 'mail',
//         children: [
//           {
//             path: '/',
//             element: (
//               <Navigate
//                 to="/dashboard/mail/all"
//                 replace
//               />
//             )
//           },
//           {
//             path: 'label/:customLabel',
//             element: <Mail />
//           },
//           {
//             path: 'label/:customLabel/:emailId',
//             element: <Mail />
//           },
//           {
//             path: ':systemLabel',
//             element: <Mail />
//           },
//           {
//             path: ':systemLabel/:emailId',
//             element: <Mail />
//           }
//         ]
//       },
//       {
//         path: 'orders',
//         children: [
//           {
//             path: '/',
//             element: <OrderList />
//           },
//           {
//             path: ':orderId',
//             element: <OrderDetails />
//           }
//         ]
//       },
//       {
//         path: 'finance',
//         element: <Finance />
//       },
//       {
//         path: 'products',
//         children: [
//           {
//             path: '/',
//             element: <ProductList />
//           },
//           {
//             path: 'new',
//             element: <ProductCreate />
//           }
//         ]
//       },
//       {
//         path: 'projects',
//         children: [
//           {
//             path: 'browse',
//             element: <ProjectBrowse />
//           },
//           {
//             path: 'new',
//             element: <ProjectCreate />
//           },
//           {
//             path: ':projectId',
//             element: <ProjectDetails />
//           }
//         ]
//       },
//       {
//         path: 'social',
//         children: [
//           {
//             path: 'feed',
//             element: <SocialFeed />
//           },
//           {
//             path: 'profile',
//             element: <SocialProfile />
//           }
//         ]
//       }
//     ]
//   },
//   {
//     path: 'docs',
//     element: <DocsLayout />,
//     children: [
//       {
//         path: '/',
//         element: (
//           <Navigate
//             to="/docs/overview/welcome"
//             replace
//           />
//         )
//       },
//       {
//         path: '*',
//         element: <Docs />
//       }
//     ]
//   },
//   {
//     path: '/dashboard',
//     element: <MainLayout />,
//     children: [
//       {
//         path: '/',
//         element: <Home />
//       },
//       {
//         path: 'browse',
//         element: <BrowseLayout />,
//         children: [
//           {
//             path: '/',
//             element: <Browse />
//           },
//           {
//             path: '/buttons',
//             element: <BrowseButtons />
//           },
//           {
//             path: '/inputs',
//             element: <BrowseInputs />
//           },
//           {
//             path: '/charts',
//             element: <BrowseCharts />
//           },
//           {
//             path: '/colors',
//             element: <BrowseColors />
//           },
//           {
//             path: '/data-display/detail-lists',
//             element: <BrowseDetailLists />
//           },
//           {
//             path: '/data-display/quick-stats',
//             element: <BrowseQuickStats />
//           },
//           {
//             path: '/data-display/tables',
//             element: <BrowseTables />
//           },
//           {
//             path: '/forms',
//             element: <BrowseForms />
//           },
//           {
//             path: '/modals',
//             element: <BrowseModals />
//           },
//           {
//             path: '/lists/grouped-lists',
//             element: <BrowseGroupedLists />
//           },
//           {
//             path: '/lists/grid-lists',
//             element: <BrowseGridLists />
//           },
//           {
//             path: '/typography',
//             element: <BrowseTypography />
//           }
//         ]
//       },
//       {
//         path: 'checkout',
//         element: <Checkout />
//       },
//       {
//         path: 'pricing',
//         element: <Pricing />
//       },
//       {
//         path: '401',
//         element: <AuthorizationRequired />
//       },
//       {
//         path: '404',
//         element: <NotFound />
//       },
//       {
//         path: '500',
//         element: <ServerError />
//       },
//       {
//         path: '*',
//         element: <NotFound />
//       }
//     ]
//   }
// ];

export default routes;
