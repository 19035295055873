import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollReset = (): null => {
  const location = useLocation();
  console.log('location', location.pathname)
   useEffect(() => {
    //document.querySelector('body').animate({ scrollTop: 0 }, 500);
  //   setTimeout(function () {
       window.scrollTo(0, 0);
  // },2);
    //window.scrollTo(0, 0);
    //document.querySelector('body').scrollTo(0,0)
    //document.getElementById('element')?.scrollIntoView({ behavior: 'smooth' });
    //document.querySelector('.mdl-layout__content').scrollTop = 0;
   
  }, [location.pathname]);

  return null;
};

export default useScrollReset;
